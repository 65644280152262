.board {
  height: 100%;
  display: flex;
}

.list{
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #a0a0a0;
  color: #000000;
  display: flex;
  width: 96vw;
  padding: 1vw 2vw;
  justify-content: space-between;
  font-size: 4vw;
  flex-shrink: 0;
}
.header__name {
  width: 15vw;
}

.header__direction {
  width: 53vw;
}

.header__time {
  width: 37vw;
  text-align: right;
}

.lines {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
  margin: 1vw 0;
  overflow: auto;
  min-height: min(49vw, calc(100% - 15vw));
}
.lines::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.footer {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #a0a0a0;
  color: #000000;
  width: 96vw;
  padding: 1vw 2vw;
  font-size: 4vw;
  flex-shrink: 0;
}