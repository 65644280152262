.line{
    display: flex;
    width: 96vw;
    justify-content: space-between;
    font-size: 4vw;
    text-shadow: 0 0 0.3vw darkorange;
    white-space: nowrap;
    flex-shrink: 0;
}

.blink {
    opacity: var(--blinking-opacity);
    transition: opacity .3s;
}

.line__name {
    width: 13vw;
    margin-right: 1vw;
    font-family: 'Bold Dot Digital-7';
    overflow: hidden;
    text-overflow: ellipsis;
}

.line__direction {
    width: 65vw;
    overflow: hidden;
    text-overflow: ellipsis;
}

.line__time {
    width: 17vw;
    text-align: right;
}