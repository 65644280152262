@font-face {
  font-family: 'Enhanced Dot Digital-7';
  src: url('EnhancedDotDigital-7.woff2') format('woff2'),
      url('EnhancedDotDigital-7.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bold Dot Digital-7';
  src: url('BoldDotDigital-7.woff2') format('woff2'),
      url('BoldDotDigital-7.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

:root {
  --blinking-opacity: 1;
}

html {
  height: 100%;
}

body {
  background-color: #101010;
  color: darkorange;
  font-family: 'Enhanced Dot Digital-7';
  font-size: 4vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#root {
  height: 100%;
}
.app {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}